<template>
  <section class="section">
    <div class="row">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>店舗基本設定</template>
          <template v-slot:body>
            <div class="form">
              <section class="form-section">
                <FormRow>
                  <template v-slot:label>店舗画像</template>
                  <template v-slot:subLabel>画像推奨サイズ：1125px × 750px</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <ul class="fileUpload">
                        <li class="fileUpload-item" v-for="(file, index) in loadingFiles" :key="index">
                          <Fileupload
                            @uploaded="setFileUpload(index, ...arguments)"
                            @removed="removeFileUpload"
                            :loading="loadingFiles[index]"
                            :initialUrl="initialImages[index]"
                            :isError="errors.images"
                          />
                        </li>
                      </ul>
                    </div>
                  </template>
                </FormRow>
                <FormRow :required="true">
                  <template v-slot:label>郵便番号</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="row">
                        <div class="col-3">
                          <input
                            v-number
                            v-maxlength
                            maxlength="7"
                            :class="{ 'form-control': true, 'is-error': errors.zipcode }"
                            type="tel"
                            name="zipcode"
                            v-model="form.zipcode"
                            @input="handleInput($event)"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </FormRow>
                <FormRow :required="true">
                  <template v-slot:label>都道府県</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-select">
                            <select
                              :class="{ 'form-control form-select-input': true, 'is-error': errors.prefectureId }"
                              name="prefectureId"
                              v-model="form.prefectureId"
                              @change="getLocation"
                            >
                              <option value="">選択してください</option>
                              <option
                                v-for="item in prefectures"
                                :key="item.id"
                                :value="item.id"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </FormRow>
                <FormRow :required="true">
                  <template v-slot:label>住所</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="form-select">
                        <select
                          :class="{ 'form-control form-select-input': true, 'is-error': errors.municipalityId }"
                          name="municipalityId"
                          v-model="form.municipalityId"
                          @change="getLocation"
                        >
                          <option value="" selected>市区群町村</option>
                          <option
                            v-for="item in municipalities"
                            :key="item.id"
                            :value="item.id"
                          >{{ item.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-content-row">
                      <input
                        v-trim
                        v-maxlength
                        maxlength="40"
                        :class="{ 'form-control': true, 'is-error': errors.streetAddress }"
                        type="text"
                        name="streetAddress"
                        placeholder="丁番地"
                        v-model="form.streetAddress"
                        @change="getLocation"
                      />
                    </div>
                    <div class="form-content-row">
                      <input
                        v-trim
                        v-maxlength
                        maxlength="40"
                        :class="{ 'form-control': true, 'is-error': errors.buildingName }"
                        type="text"
                        name="buildingName"
                        placeholder="建物名・号室"
                        v-model="form.buildingName"
                        @change="getLocation"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>地図</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <button class="btn btn-sm btn-black" type="button" @click="getLocation">住所から位置情報を取得する</button>
                    </div>
                    <div :class="{ 'form-content-row': true, 'is-error': errors.zipcode }">
                      <div id="map" style="width: 100%; height: 320px;"></div>
                    </div>
                  </template>
                </FormRow>
                <FormRow :required="true">
                  <template v-slot:label>電話番号</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <div class="row">
                        <div class="col-3">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.telephone }"
                            type="tel"
                            name="telephone"
                            v-number
                            v-maxlength
                            maxlength="11"
                            v-model="form.telephone"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>営業時間</template>
                  <template v-slot:labelNote>（50文字以内）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.onTime }"
                        type="text"
                        name="onTime"
                        v-trim
                        v-maxlength
                        maxlength="50"
                        v-model="form.onTime"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>定休日</template>
                  <template v-slot:labelNote>（20文字以内）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.holiday }"
                        type="text"
                        name="holiday"
                        v-trim
                        v-maxlength
                        maxlength="20"
                        v-model="form.holiday"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>サブタイトル</template>
                  <template v-slot:labelNote>（50文字以内）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.subTitle }"
                        type="text"
                        name="subTitle"
                        v-trim
                        v-maxlength
                        maxlength="50"
                        v-model="form.subTitle"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>紹介文</template>
                  <template v-slot:labelNote>（300文字以内）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <textarea
                        :class="{ 'form-control form-textarea': true, 'is-error': errors.description }"
                        name="description"
                        v-trim
                        v-maxlength
                        maxlength="300"
                        v-model="form.description"
                      ></textarea>
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>アクセス</template>
                  <template v-slot:labelNote>（30文字以内）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.access }"
                        type="text"
                        name="access"
                        v-trim
                        v-maxlength
                        maxlength="30"
                        v-model="form.access"
                      />
                    </div>
                  </template>
                </FormRow>
              </section>
            </div>
          </template>
        </Panel>
      </div>
    </div>
  </section>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
//helpers
import { isPhoneNumber, isZipcode } from '@/helpers/validators';
//mixins
import error from '@/mixins/plugin/error';
import { address } from '@/mixins/plugin/address';
import shop from '@/mixins/module/shop';
import googleMap from '@/mixins/module/googleMap';
//components
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';

export default {
  name: 'ShopSettingBase',
  data: function() {
    return {
      form: {
        images: [],
        zipcode: '',
        prefectureId: '',
        prefectureName: '',
        municipalityId: '',
        municipalityName: '',
        streetAddress: '',
        buildingName: '',
        telephone: '',
        onTime: '',
        holiday: '',
        subTitle: '',
        description: '',
        access: '',
        latLng: {
          lat: 0,
          lng: 0
        },
      },
      loadingFiles: [false, false, false, false],
    };
  },
  components: {
    FormRow,
    Fileupload,
  },
  mixins: [error, shop, googleMap, address({ formName: 'form' })],
  validations: {
    form: {
      images: { imageMaxLength: maxLength(4) },
      zipcode: { required, isZipcode },
      prefectureId: { selectRequired: required },
      municipalityId: { selectRequired: required },
      streetAddress: { required, maxLength: maxLength(40) },
      buildingName: { maxLength: maxLength(40) },
      telephone: { required, isPhoneNumber },
      onTime: { maxLength: maxLength(50) },
      holiday: { maxLength: maxLength(20) },
      subTitle: { maxLength: maxLength(50) },
      description: { maxLength: maxLength(300) },
      access: { maxLength: maxLength(30) },
    }
  },
  computed: {
    address() {
      return `〒${this.form.zipcode}${this.form.prefectureName}${this.form.municipalityName}${this.form.streetAddress || ''}${this.form.buildingName || ''}`;
    },
  },
  mounted() {
    this.$parent.editPage = 'base';
    if (!this.shopConfig.latLng?.latitude) this.getLocation();
  },
  watch: {
    'form.prefectureId'(val) {
      if (val) {
        this.getMunicipalities(val, this.form.municipalityId);
        this.form.prefectureName = (this.prefectures.find(item => item.id == val) || {}).name || '';
      }
    },
    'form.municipalityId'(val) {
      if (val) {
        this.form.municipalityName = (this.municipalities.find(item => item.id == val) || []).name || '';
      }
    },
  },
  methods: {
    async setFileUpload(index, url) {
      this.updateLoadingFile(true, index);
      const imageId = await this.$store.dispatch('common/uploadImage', {
        image: url,
        order: index
      });
      this.form.images = this.form.images.map(item => {
        if (item.order === index) return { id: imageId, url, order: index };
        return item;
      })
      this.updateLoadingFile(false, index);
    },
    updateLoadingFile(val, index) {
      const loadingArray = [...this.loadingFiles];
      loadingArray[index] = val;
      this.loadingFiles = loadingArray;
    },
    removeFileUpload(url) {
      const images = [...this.form.images];
      this.form.images = images.map(item => {
        if (item.url === url) return { ...item, id: null, url: null };
        return item;
      });
    },
    settingBase() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'shopSetting');
        return false;
      } else {
        const convertedForm = {
          ...this.form,
          latLng: {
            latitude: this.form.latLng.lat,
            longitude: this.form.latLng.lng,
          },
          images: this.form?.images?.filter(e => e.id),
        }
        return convertedForm;
      }
    },
  },
};
</script>
